import { gql } from '@apollo/client';

export const DEVICE_DETAIL_LOG_FRAGMENT = gql`
fragment DeviceDetailLogFragment on Device {
  logs(first: $pageSize, after: $afterCursor, filter: $filter) {
    edges {
      node {
        timestamp
        data
      }
    }
    pageInfo {
      startCursor
      endCursor
      hasNextPage
    }
  }
}
`;

export const LAMP_DETAIL_FRAGMENT = gql`
fragment LampDetailFragment on Lamp {
  mqttClientOption
  macAddress
  meshId
  switchedOn
}
`;

export const LAMP_WHITE_DETAIL_FRAGMENT = gql`
  fragment LampWhiteDetailFragment on LampWhite {
    ...LampDetailFragment
    brightness
  }
  ${LAMP_DETAIL_FRAGMENT}
`;

export const LAMP_DYNAMIC_WHITE_DETAIL_FRAGMENT = gql`
  fragment LampDynamicWhiteDetailFragment on LampDynamicWhite {
    ...LampDetailFragment
    brightness
    temperature
  }
  ${LAMP_DETAIL_FRAGMENT}
`;

export const LAMP_RGB_DETAIL_FRAGMENT = gql`
  fragment LampRGBDetailFragment on LampRGB {
    ...LampDetailFragment
    hue
    saturation
    dimmer
  }
  ${LAMP_DETAIL_FRAGMENT}
`;

export const LAMP_RGBW_DETAIL_FRAGMENT = gql`
  fragment LampRGBWDetailFragment on LampRGBW {
    ...LampDetailFragment
    hue
    saturation
    dimmer
  }
  ${LAMP_DETAIL_FRAGMENT}
`;

export const LAMP_WHITE_ON_OFF_DETAIL_FRAGMENT = gql`
  fragment LampWhiteOnOffDetailFragment on LampWhiteOnOff {
    ...LampDetailFragment
  }
  ${LAMP_DETAIL_FRAGMENT}
`;

export const KITCHEN_HOOD_DETAIL_FRAGMENT = gql`
fragment KitchenHoodDetailFragment on KitchenHood {
  mqttClientOption
  macAddress
  meshId
  configString
  lampSwitchedOn
  lampBrightness
  lampTemperature
  hoodSwitchedOn
  hoodPower
}
`;

export const DEVICE_LIST_ITEM_FRAGMENT = gql`
  fragment DeviceListItemFragment on Device {
    name
    hardwareId
    customerId
    firmwareVersion
    online
    debug
    ...on Lamp {
      macAddress
      meshId
    }
    ...on KitchenHood {
      macAddress
      meshId
    }
    __typename
  }
`;

export const DEVICE_DETAIL_FRAGMENT = gql`
  fragment DeviceDetailFragment on Device {
    ...DeviceListItemFragment
    ...LampWhiteDetailFragment
    ...LampDynamicWhiteDetailFragment
    ...LampRGBDetailFragment
    ...LampRGBWDetailFragment
    ...LampWhiteOnOffDetailFragment
    ...KitchenHoodDetailFragment
  }
  ${DEVICE_LIST_ITEM_FRAGMENT}
  ${LAMP_WHITE_DETAIL_FRAGMENT}
  ${LAMP_DYNAMIC_WHITE_DETAIL_FRAGMENT}
  ${LAMP_RGB_DETAIL_FRAGMENT}
  ${LAMP_RGBW_DETAIL_FRAGMENT}
  ${LAMP_WHITE_ON_OFF_DETAIL_FRAGMENT}
  ${KITCHEN_HOOD_DETAIL_FRAGMENT}
`;

export const DEVICES_QUERY = gql`
  query devicesQuery($afterCursor: String, $pageSize: Int!, $filter: DeviceFilter) {
    viewer {
      id
      devices(first: $pageSize, after: $afterCursor, filter: $filter) {
        edges {
          node {
            ...on Node {
              id
            }
            ...DeviceListItemFragment
          }
        }
        pageInfo {
          startCursor
          endCursor
          hasNextPage
        }
      }
    }
  }
  ${DEVICE_LIST_ITEM_FRAGMENT}
`;

export const DEVICE_LOG_QUERY = gql`
  query deviceLogQuery($id: ID!, $afterCursor: String, $pageSize: Int!, $filter: DeviceLogFilter) {
    viewer {
      id
      device(id: $id) {
        ...on Node {
          id
        }
        ...DeviceDetailFragment
        ...DeviceDetailLogFragment
      }
    }
  }
  ${DEVICE_DETAIL_FRAGMENT}
  ${DEVICE_DETAIL_LOG_FRAGMENT}
`;

export const DEVICE_SET_DEBUG_MUTATION = gql`
  mutation DeviceSetDebugMutation($input: DeviceSetDebugInput!) {
    deviceSetDebug(input: $input) {
      device {
        ...on Node {
          id
        }
        ...DeviceListItemFragment
      }
    }
  }
  ${DEVICE_LIST_ITEM_FRAGMENT}
`;

export const DEVICE_REMOVE_MUTATION = gql`
  mutation DeviceRemoveMutation($input: DeviceRemoveInput!) {
    deviceRemove(input: $input) {
      success
    }
  }
`;
