import React from 'react';
import PropTypes from 'prop-types';
import { Spin } from 'antd';
import { useTranslation } from 'react-i18next';

const Loader = (props) => {
  const {
    text,
  } = props;

  const { t } = useTranslation('translations');

  const realText = React.useMemo(() => {
    const result = text || t('common.loading');
    return result;
  }, [text, t]);

  return (
    <Spin tip={realText} size="large" />
  );
};

const propTypes = {
  text: PropTypes.string,
};

const defaultProps = {
  text: null,
};

Loader.propTypes = propTypes;
Loader.defaultProps = defaultProps;

export default Loader;
