import React from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Button,
  Row,
  Col,
  DatePicker,
} from 'antd';
import { useTranslation } from 'react-i18next';

const DeviceLogFilter = (props) => {
  const { searchObject, onSearch } = props;
  const { t } = useTranslation('translations');

  return (
    <Row>
      <Col span={24}>
        <Form
          initialValues={searchObject}
          onFinish={onSearch}
          autoComplete="off"
        >
          <Form.Item
            label={t('devices.components.logFilter.range')}
            name="range"
          >
            <DatePicker.RangePicker allowClear style={{ width: '100%' }} />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" block>
              {t('common.filter')}
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
};

const propTypes = {
  searchObject: PropTypes.shape({
    from: PropTypes.number,
    to: PropTypes.number,
  }),
  onSearch: PropTypes.func.isRequired,
};

const defaultProps = {
  searchObject: {
    form: null,
    to: null,
  },
};

DeviceLogFilter.propTypes = propTypes;
DeviceLogFilter.defaultProps = defaultProps;

export default DeviceLogFilter;
