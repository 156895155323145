export default {
  it: {
    translations: {
      common: {
        loading: 'Caricamento',
        genericError: 'Errore generico',
        filter: 'Cerca',
        loadMore: 'Carica ancora',
        actions: 'Azioni',
        details: 'Dettagli',
      },
      menu: {
        devices: 'Dispositivi',
      },
      devices: {
        list: {
          title: 'Lista dispositivi',
          table: {
            name: 'Nome',
            type: 'Tipo',
            hardwareId: 'Hardware',
            customerId: 'Cliente',
            firmwareVersion: 'Firmware',
            online: 'Online',
            macAddress: 'macAddress',
            meshId: 'MeshId',
            debugOn: 'Attiva debug',
            debugOff: 'Disattiva debug',
            delete: 'Elimina per te',
            fullRemove: '!!! ELIMINA PER TUTTI !!!',
          },
        },
        detail: {
          title: 'Dettaglio dispositivo',
          info: 'Info',
          debugOn: 'Attiva debug',
          debugOff: 'Disattiva debug',
          logTable: {
            timestamp: 'Timestamp',
            summary: 'Riassunto',
          },
        },
        components: {
          filter: {
            search: 'Ricerca',
          },
          logFilter: {
            range: 'Da - A',
          },
        },
      },
    },
  },
};
