/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'antd';
import { useTranslation } from 'react-i18next';

const GraphQLErrorAlert = (props) => {
  const {
    title,
    error,
  } = props;

  const { t } = useTranslation('translations');

  const realTitle = React.useMemo(() => {
    const result = title || t('common.genericError');
    return result;
  }, [title, t]);

  const message = React.useMemo(() => {
    if (error.graphQLErrors && error.graphQLErrors.length) {
      return error.graphQLErrors.map((e) => e.message).join(', ');
    }
    return error.message;
  }, [error]);

  return (
    <Alert
      message={realTitle}
      description={message}
      type="error"
    />
  );
};

const propTypes = {
  title: PropTypes.string,
  error: PropTypes.any.isRequired,
};

const defaultProps = {
  title: null,
};

GraphQLErrorAlert.propTypes = propTypes;
GraphQLErrorAlert.defaultProps = defaultProps;

export default GraphQLErrorAlert;
