/* eslint-disable react/no-unstable-nested-components */
import React from 'react';
import {
  PageHeader,
  Row,
  Col,
  Descriptions,
  Button,
  Tooltip,
  Table,
  Typography,
} from 'antd';
import {
  BugOutlined,
  BugFilled,
} from '@ant-design/icons';
import ReactJson from 'react-json-view';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useQuery, useMutation } from '@apollo/client';
import { DEVICE_LOG_QUERY, DEVICE_SET_DEBUG_MUTATION } from './queries';
import { GraphQLErrorAlert, Loader } from '../generic';
import { DeviceLogFilter } from './components';

const DevicePage = () => {
  const { t } = useTranslation('translations');
  const { id } = useParams();

  const [searchObject, setSearchObject] = React.useState({
    from: null,
    to: null,
  });

  const {
    error,
    loading,
    data,
    fetchMore,
  } = useQuery(DEVICE_LOG_QUERY, {
    variables: {
      id,
      pageSize: 100,
      filter: searchObject,
    },
    pollInterval: 1000,
    fetchPolicy: 'no-cache',
  });

  const [
    deviceSetDebugMutation,
    {
      error: mutationError,
    },
  ] = useMutation(DEVICE_SET_DEBUG_MUTATION);

  const queryResult = React.useMemo(() => {
    if (!loading && !error) {
      const { viewer } = data;
      const { device } = viewer;
      const { logs: logConnection } = device;
      const { edges } = logConnection;
      const logs = edges.map((e) => e.node);
      return {
        device,
        logs,
      };
    }
    return {
      device: null,
      logs: [],
    };
  }, [data, error, loading]);

  const descriptions = React.useMemo(() => {
    if (queryResult.device) {
      const result = Object.keys(queryResult.device)
        .filter((key) => !['logs'].includes(key))
        .map((key) => {
          let realValue = queryResult.device[key];
          if (typeof realValue === 'boolean') {
            realValue = realValue ? 'Yes' : 'No';
          }
          return {
            label: key,
            value: realValue,
          };
        });

      result.sort((a, b) => (a.label > b.label ? 1 : -1));
      return result;
    }
    return [];
  }, [queryResult]);

  const handleSearch = React.useCallback((newSearchObject) => {
    setSearchObject((old) => ({
      ...old,
      from: newSearchObject.range ? newSearchObject.range[0].valueOf() : null,
      to: newSearchObject.range ? newSearchObject.range[1].valueOf() : null,
    }));
  }, [setSearchObject]);

  const extra = React.useMemo(() => {
    if (queryResult.device) {
      if (queryResult.device.debug) {
        return [
          <Tooltip title={t('devices.detail.debugOff')} key="debugOff">
            <Button
              type="link"
              icon={<BugFilled />}
              onClick={() => (
                deviceSetDebugMutation({ variables: { input: { id, debug: false } } })
              )}
            />
          </Tooltip>,
        ];
      }
      return [
        <Tooltip title={t('devices.detail.debugOn')} key="debugOn">
          <Button
            type="link"
            icon={<BugOutlined />}
            onClick={() => (
              deviceSetDebugMutation({ variables: { input: { id, debug: true } } })
            )}
          />
        </Tooltip>,
      ];
    }
    return [];
  }, [queryResult, deviceSetDebugMutation]);

  const handleLoadMore = React.useCallback(() => {
    fetchMore({
      variables: {
        afterCursor: queryResult.device.logs.pageInfo.endCursor,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const { edges: oldEdges } = previousResult.viewer.device.logs;
        const { pageInfo: newPageInfo, edges: newEdges } = fetchMoreResult.viewer.device.logs;
        return newEdges.length
          ? {
            ...previousResult,
            viewer: {
              ...previousResult.viewer,
              device: {
                ...previousResult.viewer.device,
                logs: {
                  edges: [...oldEdges, ...newEdges],
                  pageInfo: newPageInfo,
                },
              },
            },
          }
          : previousResult;
      },
    });
  }, [queryResult, fetchMore]);

  const columns = React.useMemo(() => ([
    {
      title: t('devices.detail.logTable.timestamp'),
      dataIndex: 'timestamp',
      render: (timestamp) => new Date(timestamp).toLocaleString(),
      width: '20%',
    },
    {
      title: t('devices.detail.logTable.summary'),
      dataIndex: 'data',
      render: (field) => (
        <Typography.Text code>
          {JSON.stringify(JSON.parse(field), null, 2).slice(0, 100)}
          {field.length > 100 ? '...' : ''}
        </Typography.Text>
      ),
    },
  ]), [t]);

  const tableFooter = React.useMemo(() => {
    if (queryResult.device && queryResult.device.logs.pageInfo.hasNextPage) {
      return () => (
        <Button type="primary" block onClick={handleLoadMore}>
          {t('common.loadMore')}
        </Button>
      );
    }
    return null;
  }, [queryResult, handleLoadMore]);

  return (
    <>
      <Row>
        <Col span={24}>
          <PageHeader
            title={t('devices.detail.title')}
            extra={extra}
          />
        </Col>
      </Row>
      {error && (
        <Row>
          <Col span={24}>
            <GraphQLErrorAlert error={error} />
          </Col>
        </Row>
      )}
      {mutationError && (
        <Row>
          <Col span={24}>
            <GraphQLErrorAlert error={mutationError} />
          </Col>
        </Row>
      )}
      {loading && (
        <Row justify="center" align="center">
          <Col span={24}>
            <Loader />
          </Col>
        </Row>
      )}
      <Row>
        <Col span={24}>
          <Descriptions title={t('devices.detail.info')}>
            {descriptions.map((description) => (
              <Descriptions.Item
                label={description.label}
                key={description.label}
              >
                {description.value}
              </Descriptions.Item>
            ))}
          </Descriptions>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <DeviceLogFilter
            searchObject={searchObject}
            onSearch={handleSearch}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Table
            columns={columns}
            rowKey={(record) => record.timestamp}
            dataSource={queryResult.logs}
            loading={loading}
            pagination={false}
            footer={tableFooter}
            expandable={{
              expandedRowRender: (record) => (
                <ReactJson src={JSON.parse(record.data)} />
              ),
            }}
          />
        </Col>
      </Row>
    </>
  );
};

export default DevicePage;
