// const config = {
//   graphQLUri: 'http://localhost:4000/graphql',
//   oauthUrl: 'http://localhost:4001/oauth/authorize',
//   logoutUrl: 'http://localhost:4001/logout?redirectTo=http://localhost:3001',
//   client_id: 'adminFrontend',
//   redirect_uri: 'http://localhost:3000/login',
//   languages: ['it'],
//   defaultLanguage: 'it',
// };

const config = {
  graphQLUri: 'https://s-devices-cobofra.geekcups.com/graphql',
  oauthUrl: 'https://s-login-cobofra.geekcups.com/oauth/authorize',
  logoutUrl: 'https://s-login-cobofra.geekcups.com/logout?redirectTo=https://s-admin-devices-cobofra.geekcups.com',
  client_id: 'mqp8o1c6sk2qmitc79303c0xay7d51',
  redirect_uri: 'https://s-admin-devices-cobofra.geekcups.com/login',
  languages: ['it'],
  defaultLanguage: 'it',
};

// const config = {
//   graphQLUri: 'https://graphql.giada-system.com/graphql',
//   oauthUrl: 'https://login.giada-system.com/oauth/authorize',
//   logoutUrl: 'https://login.giada-system.com/logout?redirectTo=https://admin.giada-system.com',
//   client_id: '0e4fmu2xh65gv8t7y5h15uc4ry4r5c',
//   redirect_uri: 'https://admin.giada-system.com/login',
//   languages: ['it'],
//   defaultLanguage: 'it',
// };

export default config;
