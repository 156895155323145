/* eslint-disable react/no-unstable-nested-components */
import React from 'react';
import {
  PageHeader,
  Row,
  Col,
  Table,
  Button,
  Tooltip,
} from 'antd';
import {
  CheckOutlined,
  CloseOutlined,
  RightCircleOutlined,
  BugOutlined,
  BugFilled,
  DeleteOutlined,
  FrownOutlined,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useQuery, useMutation } from '@apollo/client';
import { DEVICES_QUERY, DEVICE_REMOVE_MUTATION, DEVICE_SET_DEBUG_MUTATION } from './queries';
import { GraphQLErrorAlert } from '../generic';

import { DeviceFilter } from './components';

const DeviceListPage = () => {
  const { t } = useTranslation('translations');

  const [searchObject, setSearchObject] = React.useState({
    all: true,
  });

  const {
    error,
    loading,
    data,
    fetchMore,
  } = useQuery(DEVICES_QUERY, {
    variables: {
      pageSize: 100,
      filter: searchObject,
    },
    fetchPolicy: 'no-cache',

  });

  const [
    deviceSetDebugMutation,
    {
      error: mutationDebugError,
    },
  ] = useMutation(DEVICE_SET_DEBUG_MUTATION);

  const [
    deviceRemoveMutation,
    {
      error: mutationRemoveError,
    },
  ] = useMutation(DEVICE_REMOVE_MUTATION, {
    refetchQueries: () => {
      const result = [
        {
          query: DEVICES_QUERY,
          variables: {
            pageSize: 100,
            filter: searchObject,
          },
        },
      ];
      return result;
    },
    awaitRefetchQueries: true,
  });

  const queryResult = React.useMemo(() => {
    if (!loading && !error) {
      const { viewer } = data;
      const { devices: deviceConnection } = viewer;
      const { pageInfo, edges } = deviceConnection;
      return {
        devices: edges.map((e) => e.node),
        pageInfo,
      };
    }
    return {
      workspaces: [],
      pageInfo: null,
    };
  }, [data, error, loading]);

  const handleLoadMore = React.useCallback(() => {
    fetchMore({
      variables: {
        afterCursor: queryResult.pageInfo.endCursor,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const { edges: oldEdges } = previousResult.viewer.devices;
        const { pageInfo: newPageInfo, edges: newEdges } = fetchMoreResult.viewer.devices;
        return newEdges.length
          ? {
            ...previousResult,
            viewer: {
              ...previousResult.viewer,
              devices: {
                ...previousResult.viewer.devices,
                edges: [...oldEdges, ...newEdges],
                pageInfo: newPageInfo,
              },
            },
          }
          : previousResult;
      },
    });
  }, [queryResult, fetchMore]);

  const handleSearch = React.useCallback((newSearchObject) => {
    setSearchObject((old) => ({
      ...old,
      ...newSearchObject,
    }));
  }, [setSearchObject]);

  const columns = React.useMemo(() => ([
    {
      title: t('devices.list.table.name'),
      dataIndex: 'name',
    },
    {
      title: t('devices.list.table.type'),
      dataIndex: '__typename',
    },
    {
      title: t('devices.list.table.hardwareId'),
      dataIndex: 'hardwareId',
    },
    {
      title: t('devices.list.table.customerId'),
      dataIndex: 'customerId',
    },
    {
      title: t('devices.list.table.firmwareVersion'),
      dataIndex: 'firmwareVersion',
    },
    {
      title: t('devices.list.table.macAddress'),
      dataIndex: 'macAddress',
    },
    {
      title: t('devices.list.table.meshId'),
      dataIndex: 'meshId',
    },
    {
      title: t('devices.list.table.online'),
      dataIndex: 'online',
      render: (online) => (online ? <CheckOutlined /> : <CloseOutlined />),
    },
    {
      title: t('common.actions'),
      dataIndex: 'id',
      render: (id, record) => {
        const result = (
          <>
            <Tooltip title={t('devices.list.table.delete')}>
              <Button
                type="link"
                icon={<DeleteOutlined />}
                onClick={() => (
                  deviceRemoveMutation({ variables: { input: { id: record.macAddress } } })
                )}
              />
            </Tooltip>
            <Link to={`/devices/${id}`}>
              <Tooltip title={t('common.details')}>
                <RightCircleOutlined />
              </Tooltip>
            </Link>
            {record.debug
              ? (
                <Tooltip title={t('devices.list.table.debugOff')}>
                  <Button
                    type="link"
                    icon={<BugFilled />}
                    onClick={() => (
                      deviceSetDebugMutation({ variables: { input: { id, debug: false } } })
                    )}
                  />
                </Tooltip>
              )
              : (
                <Tooltip title={t('devices.list.table.debugOn')}>
                  <Button
                    type="link"
                    icon={<BugOutlined />}
                    onClick={() => (
                      deviceSetDebugMutation({ variables: { input: { id, debug: true } } })
                    )}
                  />
                </Tooltip>
              )}
            <Tooltip title={t('devices.list.table.fullRemove')}>
              <Button
                type="link"
                icon={<FrownOutlined />}
                onClick={() => (
                  deviceRemoveMutation({
                    variables: {
                      input: {
                        id: record.macAddress,
                        fullRemove: true,
                      },
                    },
                  })
                )}
              />
            </Tooltip>
          </>
        );
        return result;
      },
    },
  ]), [t]);

  const tableFooter = React.useMemo(() => {
    if (queryResult.pageInfo && queryResult.pageInfo.hasNextPage) {
      return () => (
        <Button type="primary" block onClick={handleLoadMore}>
          {t('common.loadMore')}
        </Button>
      );
    }
    return null;
  }, [queryResult, handleLoadMore]);

  return (
    <>
      <Row>
        <Col span={24}>
          <PageHeader
            title={t('devices.list.title')}
          />
        </Col>
      </Row>
      {error && (
        <Row>
          <Col span={24}>
            <GraphQLErrorAlert error={error} />
          </Col>
        </Row>
      )}
      {(mutationDebugError || mutationRemoveError) && (
        <Row>
          <Col span={24}>
            <GraphQLErrorAlert error={mutationDebugError || mutationRemoveError} />
          </Col>
        </Row>
      )}
      <Row>
        <Col span={24}>
          <DeviceFilter
            searchObject={searchObject}
            onSearch={handleSearch}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Table
            columns={columns}
            rowKey={(record) => record.id}
            dataSource={queryResult.devices}
            loading={loading}
            pagination={false}
            footer={tableFooter}
          />
        </Col>
      </Row>
    </>
  );
};

export default DeviceListPage;
