import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';

import { ApolloProvider } from '@apollo/client';
import { BrowserRouter } from 'react-router-dom';

import reportWebVitals from './reportWebVitals';
import { AuthenticationProvider } from './authentication';
import { AuthenticatedLayout } from './layouts';

import apolloClient from './apollo';
import './i18n';

const MainApp = () => (
  <ApolloProvider client={apolloClient}>
    <BrowserRouter>
      <AuthenticationProvider>
        <AuthenticatedLayout />
      </AuthenticationProvider>
    </BrowserRouter>
  </ApolloProvider>
);

ReactDOM.render(<MainApp />, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
