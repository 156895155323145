import React from 'react';
import { useApolloClient } from '@apollo/client';
import PropTypes from 'prop-types';
import jwtDecode from 'jwt-decode';

import {
  useLocation,
  useNavigate,
} from 'react-router-dom';

import config from '../config';

const isAuthenticated = () => {
  const token = window.localStorage.getItem('token');
  if (!token) return false;

  const decoded = jwtDecode(token);
  const { exp } = decoded;
  const expirationDate = new Date(exp * 1000);

  if (new Date() > expirationDate) return false;

  return {
    ...decoded,
    token,
  };
};

const getCurrentUser = () => isAuthenticated();

export const AuthenticationContext = React.createContext();

export const AuthenticationProvider = (props) => {
  const { children } = props;
  const location = useLocation();
  const navigate = useNavigate();

  const apolloClient = useApolloClient();
  const [currentUser, setCurrentUser] = React.useState(getCurrentUser());

  React.useEffect(() => {
    if (!currentUser) {
      if (location && location.hash) {
        const token = location.hash.split('access_token=').pop().split('&token_type=Bearer')[0];
        if (token) {
          window.localStorage.setItem('token', token);
          setCurrentUser(getCurrentUser());
          navigate('/devices', { replace: true });
        }
      }
      else {
        const URL = `${config.oauthUrl}?client_id=${config.client_id}&redirect_uri=${config.redirect_uri}&response_type=token`;
        // console.log('URL: ', URL);
        window.location.href = URL;
      }
    }
    return null;
  }, [currentUser, location, navigate]);

  const logout = React.useCallback(async () => {
    window.localStorage.removeItem('token');
    window.location = config.logoutUrl;
  }, []);

  const value = React.useMemo(() => ({
    currentUser,
    logout,
  }), [currentUser, logout]);

  return (
    <AuthenticationContext.Provider
      value={value}
    >
      {currentUser ? children : null}
    </AuthenticationContext.Provider>
  );
};

const propTypes = {
  children: PropTypes.node.isRequired,
};

AuthenticationProvider.propTypes = propTypes;

export const { Consumer: AuthenticationConsumer } = AuthenticationContext;
