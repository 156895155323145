import React from 'react';
import { Button, Layout, Menu } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  Routes,
  Route,
  Outlet,
  Link,
  useLocation,
} from 'react-router-dom';

import {
  DeviceListPage,
  DevicePage,
} from '../devices';
import { AuthenticationContext } from '../authentication';

const { Header, Content } = Layout;

const AuthenticationLayout = () => {
  const { t } = useTranslation('translations');
  const location = useLocation();
  const { logout } = React.useContext(AuthenticationContext);

  const selectedMenuKeys = React.useMemo(() => {
    const result = [];
    if (location.pathname.includes('/devices')) {
      result.push('devices');
    }
    return result;
  }, [location]);

  return (
    <Layout>
      <Header>
        <Menu theme="dark" mode="horizontal" selectedKeys={selectedMenuKeys}>
          <Menu.Item key="devices">
            <Link to="/devices">
              {t('menu.devices')}
            </Link>
          </Menu.Item>
        </Menu>
        <div style={{ float: 'right' }}>
          <Button onClick={logout}>Logout</Button>
        </div>
      </Header>
      <Content style={{ padding: 50 }}>
        <Routes>
          <Route path="devices" element={<Outlet />}>
            <Route path=":id" element={<DevicePage />} />
            <Route path="" element={<DeviceListPage />} />
          </Route>
        </Routes>
      </Content>
    </Layout>
  );
};

export default AuthenticationLayout;
